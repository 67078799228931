import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layouts/layout"
import Seo from "../components/seo"

const ErrorPage = () => (
    <Layout jobCode="PP-AUR-US-1407 (v2.0)" jobCodeDate="03/22">
        <Helmet>
            <html lang="en" />
            <body className="" id="landing-500" />
        </Helmet>
        <Seo
            title="Technical difficulties"
            description="Technical difficulties for the AURYXIA site. Find all the information you need about FDA-approved AURYXIA® (ferric citrate) tablets."
        />
        <section id="hero" className="flood-hp col-6 center">
            <div className="flex-row">
                <div>
                    <h1>Our apologies</h1>
                    <p>
                        The system is currently experiencing technical
                        difficulties. This error has been recorded and will be
                        addressed promptly.
                    </p>
                </div>
            </div>
        </section>
    </Layout>
)

export default ErrorPage
